.cximg {
    max-height: 200px;
    width: auto;   
    margin-right: .5em;    
}

.addimages {
    max-width: 100%;
    height: auto;    
}

iframe {
    width: 560px;    
    height: 315px;
    max-width: 90%;
    display: block;
    margin-right: auto;
    margin-left: auto;        
}

