* {
    font-family: 'Poppins', sans-serif;
} 

.erie {   
    background-image: url('../images/eriemarathon2015.jpg');    
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;      
    position: relative;
    top: 0;   
    left: 0; 
    z-index: 0;
}

.eriefullscreen {
    min-height: calc(100vh);  
    background-image: url('../images/eriemarathon2015.jpg');    
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;      
    position: relative;
    top: 0;   
    left: 0; 
    z-index: 0;   
}

.overlay {   
    width: 100%;  
    min-height: 100%;    
    background-color: transparent; 
    background-color: black;
    background-size:cover;    
    padding: 0;  
    opacity: .6;
    position: absolute;
    top: 0;   
    left: 0;   
    z-index: -1;
}

