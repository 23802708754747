* {
    font-family: 'Poppins', sans-serif;
  } 
  
.balloon {    
    width: 40em; 
    max-width: 60%;   
    margin-top: 5em;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
    